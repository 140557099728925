import kimkbbq from "./kimkbbq.png";
import micron from "./micron.png";
import soundstream from "./soundstream.png";

export const sponsorships = [
  {
    name: "Micron",
    logo: micron,
  },
  {
    name: "I'm Kim Korean BBQ",
    logo: kimkbbq,
  },

  {
    name: "Soundstream",
    logo: soundstream,
  },
];
